import { InvestorEntityProvider } from '../../enums';
import { InvestorAccountProviderDetails } from '../../types';

export const investorAccountProvider: InvestorAccountProviderDetails<InvestorEntityProvider.YS> = {
  type: InvestorEntityProvider.YS,
  data: null,
};

export const investorAccountProviderETC: InvestorAccountProviderDetails<InvestorEntityProvider.ETC> =
  {
    type: InvestorEntityProvider.ETC,
    data: {
      etc_owner_contact_id: '2200044',
      etc_account_number: '205350023',
    },
  };
