import { InvestorAccount } from '../../types';
import {
  ExternalIdentityDocumentStatus,
  ExternalIdentityProvider,
  ExternalIdentityYieldStreetStatus,
  IdentityDocumentType,
  InvestorEntityStatus,
  InvestorEntityType,
  InvestorEntityTypeName,
  InvestorEntityTypeValue,
} from '../../enums';

import { aiqIncome } from './investor-account-aiq';
import { investorAccountProvider } from './investor-account-provider';

export const investorAccount: InvestorAccount = {
  id: 201924,
  investorId: 220951,
  name: 'John Doe',
  firstName: 'John',
  lastName: 'Doe',
  signerTitle: null,
  type: {
    entityType: InvestorEntityType.INDIVIDUAL,
    identityDocumentType: IdentityDocumentType.EIN,
    iraAccountType: null,
    name: InvestorEntityType.INDIVIDUAL,
    value: InvestorEntityTypeValue.INDV,
  },
  entityType: InvestorEntityType.INDIVIDUAL,
  documentType: IdentityDocumentType.SSN,
  externalIdentityId: 52123,
  externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
  externalIdentityProviderId: '5eeb9db17087fe5d12a93a68',
  identityVerificationStatus: ExternalIdentityYieldStreetStatus.VERIFIED_SEND_AND_RECEIVE,
  documentVerificationStatus: ExternalIdentityDocumentStatus.VALID,
  governmentIdDocumentStatus: ExternalIdentityDocumentStatus.VALID,
  needsSsnEin: false,
  needsGovtId: false,
  needsUpdate: false,
  addressLine1: '257 Gold Street',
  addressLine2: '',
  city: 'Brooklyn',
  state: 'NY',
  postalCode: '11201',
  country: 'US',
  verificationStatus: InvestorEntityStatus.PENDING,
  hasMultipleOwners: false,
  used: false,
  verified: false,
  documentUpdateRequired: false,
  dateOfRecord: '1988-08-21',
  createDate: 1592499633000,
  lastInvestmentDate: null,
  fingerprint: null,
  autoInvestEnabled: false,
  hasWallet: true,
  walletEnabled: true,
  bankAccountsLimitExceeded: false,
  walletBankAccountId: 91245,
  isIra: false,
  iraAccountType: null,
  iraAccountTypeDisplayName: null,
  penscoAccountRequested: false,
  employerName: null,
  aiq: [aiqIncome],
  externalIdentityProviderDisplayName: 'SynapsePay',
  identityVerificationStatusDisplayName: 'Verified (Send and Receive)',
  documentVerificationStatusDisplayName: 'Valid',
  ira: false,
  consolidatedType: InvestorEntityTypeName.INDIVIDUAL,
  entityTypeDisplayName: null,
  typeDisplayName: 'Individual',
  verificationStatusDisplayName: 'Pending',
  provider: investorAccountProvider,
  affiliationDetails: null,
};

export const brandNewInvestorAccount: InvestorAccount = {
  id: 303150,
  investorId: 365647,
  name: 'Yes Tyws',
  firstName: 'Yes',
  lastName: 'Tyws',
  signerTitle: null,
  entityType: InvestorEntityType.INDIVIDUAL,
  externalIdentityId: 197902,
  externalIdentityProviderId: '61e7033ea8431243c1ce7596',
  needsSsnEin: false,
  needsGovtId: true,
  needsUpdate: true,
  addressLine1: '550 1st Avenue',
  addressLine2: '',
  city: 'New York',
  state: 'NY',
  postalCode: '10016',
  country: 'US',
  hasMultipleOwners: false,
  used: false,
  verified: false,
  documentUpdateRequired: false,
  dateOfRecord: '2000-10-10',
  createDate: 1642529596000,
  lastInvestmentDate: null,
  fingerprint: '4WW2k3t82OGUTLMSGC+l40UXCjkT/UO7YlkuKRxjOIU=',
  autoInvestEnabled: false,
  hasWallet: true,
  walletEnabled: true,
  bankAccountsLimitExceeded: false,
  walletBankAccountId: 317670,
  isIra: false,
  iraAccountType: null,
  iraAccountTypeDisplayName: null,
  penscoAccountRequested: false,
  employerName: '',
  aiq: [],
  consolidatedType: InvestorEntityTypeName.INDIVIDUAL,
  entityTypeDisplayName: null,
  externalIdentityProviderDisplayName: 'SynapsePay',
  identityVerificationStatusDisplayName: 'Verified (Send and Receive)',
  documentVerificationStatusDisplayName: 'Valid',
  ira: false,
  typeDisplayName: 'Individual',
  verificationStatusDisplayName: 'MISSING',
  type: {
    entityType: InvestorEntityType.INDIVIDUAL,
    identityDocumentType: IdentityDocumentType.EIN,
    iraAccountType: null,
    name: InvestorEntityType.INDIVIDUAL,
    value: InvestorEntityTypeValue.INDV,
  },
  documentType: IdentityDocumentType.SSN,
  externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
  identityVerificationStatus: ExternalIdentityYieldStreetStatus.MISSING,
  documentVerificationStatus: ExternalIdentityDocumentStatus.MISSING,
  governmentIdDocumentStatus: ExternalIdentityDocumentStatus.MISSING,
  verificationStatus: InvestorEntityStatus.PENDING,
  provider: investorAccountProvider,
  affiliationDetails: null,
};
