import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  InvestorEntity,
  InvestorEntityStatusInterface,
  InvestorEntityStepProvider,
  InvestorEntityStepVerificationDetails,
  InvestorEntityStatus,
  allToken,
  investorEntityStatusApi,
} from '@yieldstreet/platform-kit';

import { useAllInvestorEntities } from './useAllInvestorEntities';
import { useApiQueryPollCheck } from '../../async-effects';
import { useAuth } from '../../auth';

const buildInvestorEntityStatus = (
  investorEntities: InvestorEntity[],
  investorEntityStatuses: {
    [id: string]: InvestorEntityStatusInterface;
  }
) =>
  investorEntities.map(investorEntity => ({
    investorEntity,
    investorEntityStatus: investorEntityStatuses[investorEntity.id],
  }));

export const getInvestorEntity = (
  investorEntityId: string | number,
  investorEntities: InvestorEntity[],
  investorEntityStatuses: {
    [id: string]: InvestorEntityStatusInterface;
  }
) => {
  return buildInvestorEntityStatus(investorEntities, investorEntityStatuses).find(
    ({ investorEntity }) => Number(investorEntity.id) === Number(investorEntityId)
  );
};

export const getInvestorEntityByInvestorAccountId = (
  investorAccountId: string | number,
  investorEntities: InvestorEntity[]
) =>
  investorEntities.find(entity =>
    entity.investorAccounts.find(acct => Number(acct.id) === Number(investorAccountId))
  );

export const useInvestorEntity = (investorEntityId: string | number) => {
  const allInvestorsState = useAllInvestorEntities();

  return useMemo(() => {
    const { investorEntities, investorEntityStatuses, ...allState } = allInvestorsState;

    const result =
      investorEntities &&
      investorEntityStatuses &&
      getInvestorEntity(investorEntityId, investorEntities, investorEntityStatuses);

    return { ...result, ...allState };
  }, [allInvestorsState, investorEntityId]);
};

export const useInvestorEntityStatusPollCheck = (
  investorEntityId: string | number,
  statusCheckCallback: (status: InvestorEntityStatusInterface) => boolean,
  skipAutoCheck?: boolean
) => {
  const { isAuthenticated } = useAuth();
  const token = isAuthenticated ? allToken : skipToken;
  const investorEntityStatusesResult =
    investorEntityStatusApi.useGetAllInvestorEntityStatusQuery(token);

  const { data: investorEntityStatuses, ...pollCheckState } = useApiQueryPollCheck(
    investorEntityStatusesResult,
    ({ [investorEntityId]: investorEntityStatus }) =>
      investorEntityStatus && statusCheckCallback(investorEntityStatus),
    skipAutoCheck
  );

  const { [investorEntityId]: investorEntityStatus } = investorEntityStatuses || {};

  return { data: investorEntityStatus, ...pollCheckState };
};

export const isInvestorEntityStatusActionable = ({
  provider,
  status,
}: InvestorEntityStepVerificationDetails<any>) =>
  [InvestorEntityStatus.ACTION_NEEDED, InvestorEntityStatus.MISSING].includes(status) ||
  // very very very dirty and temporary synapse hack
  (provider === InvestorEntityStepProvider.YS && status === InvestorEntityStatus.FAILED);
