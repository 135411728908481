/**
 * Investor entity statuses for Identity, Bank and Accreditation.
 */
export const enum InvestorEntityStatus {
  ACTION_NEEDED = 'ACTION_NEEDED',
  ATTENTION = 'ATTENTION',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
  /**
   * TODO: The new endpoint uses INADMISSABLE instead of INVALID.
   * It will be changed to INVALID in this ticket:
   * https://app.shortcut.com/yieldstreet/story/173720/investor-entity-identity-status-inadmissible-invalid
   */
  INVALID = 'INVALID',
  MISSING = 'MISSING',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
}
