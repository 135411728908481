import {
  InvestorEntity,
  InvestorEntityActionCode,
  InvestorEntityStatusInterface,
} from '@yieldstreet/platform-kit';

import { AppAction, AppActionConfig } from '../../app-actions';
import { appActionManager } from '../../app-actions/appActionManager';

import { getInvestorEntityType } from './useInvestorEntityType';

export const getInvestorEntityAppAction = (
  action: InvestorEntityActionCode,
  investorEntity: InvestorEntity,
  investorEntityStatus: InvestorEntityStatusInterface
): AppActionConfig<AppAction> => {
  const { id } = investorEntity;

  const investorEntityType = getInvestorEntityType(investorEntity);

  switch (action) {
    case InvestorEntityActionCode.SET_KYC_1:
      return appActionManager.getAction(AppAction.START_KYC, {
        investorEntityId: id,
      });
    case InvestorEntityActionCode.SET_KYC_2:
      return appActionManager.getAction(AppAction.CONTINUE_KYC, {
        investorEntityId: id,
        investorEntity,
      });
    case InvestorEntityActionCode.INFORM_MAIN_BENEFICIAL_OWNER_DATA:
      return appActionManager.getAction(AppAction.SUBMIT_BENEFICIAL_OWNERS_KYC_INFO, {
        investorEntityId: id,
        investorEntityType,
      });
    case InvestorEntityActionCode.UPLOAD_MAIN_BENEFICIAL_OWNERS_DOCUMENT:
      return appActionManager.getAction(AppAction.UPLOAD_BENEFICIAL_OWNERS_KYC_DOCS, {
        investorEntityId: id,
        investorEntityType,
      });
    case InvestorEntityActionCode.INVITE_BENEFICIAL_OWNERS:
      return appActionManager.getAction(AppAction.INVITE_BENEFICIAL_OWNERS, {
        investorEntityId: id,
        investorEntityType,
      });
    case InvestorEntityActionCode.LINK_BANK_ACCOUNT:
      return appActionManager.getAction(AppAction.LINK_BANK_ACCOUNT, {
        investorEntityId: id,
      });
    case InvestorEntityActionCode.VERIFY_ACCREDITATION:
      return appActionManager.getAction(AppAction.SUBMIT_ACCREDITATION, {
        investorEntityId: id,
        investorEntity,
        investorEntityStatus,
      });
    case InvestorEntityActionCode.EDIT_ACCREDITATION:
      return appActionManager.getAction(AppAction.EDIT_ACCREDITATION, {
        investorEntityId: id,
        investorEntity,
        investorEntityStatus,
      });
    case InvestorEntityActionCode.UPDATE_ACCREDITATION:
      return appActionManager.getAction(AppAction.RENEW_ACCREDITATION, {
        investorEntityId: id,
        investorEntity,
        investorEntityStatus,
      });
    case InvestorEntityActionCode.UPDATE_ACCREDITATION_DOCUMENTATION:
      return appActionManager.getAction(AppAction.UPDATE_ACCREDITATION_DOCUMENTATION, {
        investorEntityId: id,
        investorEntity,
        investorEntityStatus,
      });
    case InvestorEntityActionCode.VERIFY_MICRO_DEPOSITS:
      return appActionManager.getAction(AppAction.VERIFY_MICRO_DEPOSITS, {
        investorEntityId: id,
      });
    case InvestorEntityActionCode.VERIFY_BENEFICIARIES:
      return appActionManager.getAction(AppAction.VERIFY_IRA_BENEFICIARIES, {
        investorEntityId: id,
      });
    case InvestorEntityActionCode.SET_IRA_FEE:
      return appActionManager.getAction(AppAction.SET_IRA_FEE, {
        investorEntityId: id,
      });
    case InvestorEntityActionCode.SIGN_IRA_DOC:
      return appActionManager.getAction(AppAction.SIGN_IRA_DOC, {
        investorEntityId: id,
      });
    case InvestorEntityActionCode.FUND_IRA:
      return appActionManager.getAction(AppAction.FUND_IRA, {
        investorEntityId: id,
      });
    case InvestorEntityActionCode.ITS_ON_US:
      return appActionManager.getAction(AppAction.CONTACT_IR, {});
  }
};
