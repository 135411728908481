import { InvestorEntityProvider } from '../../enums';
import { InvestorAccountAction, InvestorAccountProviderDetails } from '../../types';

// @deprecated please migrate to new elibility endpoint
export type BulkInvestmentType = 'DISCRETIONARY' | 'NON_DISCRETIONARY';

// @deprecated please migrate to new elibility endpoint
export interface EligibilityGateAttributes {
  usStateName?: string;
  usState?: string;
  reasonCode?: InvestorLevelGateCode | UserLevelGateCode;
}

// @deprecated please migrate to new elibility endpoint
export interface EligibilityGateData<T> {
  gateCode: T;
  gateText: string;
  attributes?: EligibilityGateAttributes;
}

// @deprecated please migrate to new elibility endpoint
export interface BulkInvestmentEligibilityInvestments {
  urlHash: string;
  amount: number;
}

// @deprecated please migrate to new elibility endpoint

export interface BulkInvestmentEligibilityRequest {
  type?: BulkInvestmentType;
  investments: BulkInvestmentEligibilityInvestments[];
  rolloverAmountAvailable?: number;
  rolloverSourceUrlHash?: string;
  rolloverInvestmentIds?: string[];
}

// @deprecated please migrate to new elibility endpoint
export enum InvestorLevelGateCode {
  /**
   * Investor blocked from investing due to its account type (ex IRA in a non-IRA offering)
   */
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',

  /**
   * The offering status does not allow the investor to invest
   */
  OFFERING_STATUS = 'OFFERING_STATUS',

  /**
   * Account is from restricted state
   */
  STATE_INELIGIBLE = 'STATE_INELIGIBLE',

  /**
   * Wallet balance needs to cover the entire investment amount in certain scenarios
   */
  INSUFFICIENT_WALLET_BALANCE = 'INSUFFICIENT_WALLET_BALANCE',

  /**
   * Account is not ready to invest, and requires further action from the user
   */
  ACCOUNT_ACTION = 'ACCOUNT_ACTION',

  /**
   * ETC account is not ready to invest, user should wait for entity provider
   */
  INVESTOR_ENTITY_PROVIDER_ACTION = 'INVESTOR_ENTITY_PROVIDER_ACTION',

  /**
   * Entities with more than 25% ownership by non us citizen
   */
  KYC_INELIGIBLE = 'KYC_INELIGIBLE',

  /**
   * Entity without a linked bank account
   */
  MISSING_BANK_ACCOUNTS = 'MISSING_BANK_ACCOUNTS',

  /**
   * Entity has a bank account but has pending micro deposit verification
   */
  PENDING_MICRO_DEPOSITS = 'PENDING_MICRO_DEPOSITS',

  /*
   * There are connected bank accounts, but they are still pending verification from the bank account linking provider.
   */
  PENDING_BANK_ACCOUNT_VERIFICATION = 'PENDING_BANK_ACCOUNT_VERIFICATION',

  /**
   * Investor should have never got to this point
   */
  NEVER = 'NEVER',

  /**
   * Something went wrong
   */
  ERROR = 'ERROR',
}

export interface BulkInvestmentEligibilityInvestorData {
  investorAccountId: number;
  investorEntityId?: number;
  name: string;
  readyToInvest: boolean;
  ysIra: boolean;
  action: InvestorAccountAction | null;
  gateData: EligibilityGateData<InvestorLevelGateCode> | null;
  urlHashReason: Record<string, string>;
  provider: InvestorAccountProviderDetails<InvestorEntityProvider>;
}

export enum UserLevelGateCode {
  /**
   * User is not in the offer's audience segment
   */
  SEGMENTATION = 'SEGMENTATION',

  /**
   * User fails the self accreditation requirement of the offering
   */
  SELF_ACCREDITATION = 'SELF_ACCREDITATION',

  /**
   * User fails the QP accreditation requirement of the offering
   */
  SELF_QP = 'SELF_QP',

  /**
   * User fails the QC accreditation requirement of the offering
   */
  SELF_QC = 'SELF_QC',

  /**
   * User still has to add an Investor Account in order to be able to invest
   */
  NO_INVESTOR_ACCOUNT = 'NO_INVESTOR_ACCOUNT',

  /**
   * All the user's Investor Accounts require some action to complete setup
   */
  ACCOUNT_GATE = 'ACCOUNT_GATE',

  /**
   * The user should have never ended up with an investment intent on this offering
   */
  NEVER = 'NEVER',

  /**
   * Something went wrong
   */
  ERROR = 'ERROR',
}

// @deprecated please migrate to new elibility endpoint
export interface BulkInvestmentEligibilityResult {
  userGate: EligibilityGateData<UserLevelGateCode> | null;
  investorData: BulkInvestmentEligibilityInvestorData[];
}

// @deprecated please migrate to new elibility endpoint
export interface BulkInvestmentEligibilityApiResponse {
  success: boolean;
  eligibility: BulkInvestmentEligibilityResult;
}
